import axios from 'axios'

window.axios = axios

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'

/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */

// import Echo from 'laravel-echo'
// import Pusher from 'pusher-js'
// window.Pusher = Pusher

// window.Echo = new Echo({
//   broadcaster: 'pusher',
//   // I can't seem to get the key to use `import.meta.env.VITE_PUSHER_APP_KEY`,
//   // so here they are. Change these to receive Pusher messages.
//   // key: 'eed8c359a879536effc7', // dev key
//   key: '911aa2b28b79d75b3da3', // staging & testing key
//   // key: 'ce270ce86fc4d8aee2ba', // prod key
//   cluster: 'eu', //import.meta.env.VITE_PUSHER_APP_CLUSTER,
//   encrypted: true,
// })
