import './../css/app.css'
import { createInertiaApp, Head, Link } from '@inertiajs/vue2'
import * as Sentry from '@sentry/vue'
import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import relativeTime from 'dayjs/plugin/relativeTime'
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers'
import { DatePickerPlugin } from 'nm/@hammerstone/refine-vue2'
import vClickOutside from 'nm/v-click-outside'
import VuePaginate from 'nm/vue-paginate'
import DatePicker from 'nm/vue2-datepicker'
import PortalVue from 'portal-vue'
import VTooltip from 'v-tooltip'
import Vue from 'vue'
import Vuex from 'vuex'

import { ZiggyVue } from '../../vendor/tightenco/ziggy/dist/vue.m'
import './bootstrap'
import storeData from './store.js'

Sentry.init({
  Vue: Vue,
  dsn: import.meta.env.VITE_SENTRY_DSN_PUBLIC,
  environment: import.meta.env.VITE_SENTRY_ENV,
  release: import.meta.env.VITE_APP_VERSION,
  trackComponents: true,
  tracesSampleRate: 0.5,
  sendDefaultPii: true,
  replaysSessionSampleRate: 0.3,
  replaysOnErrorSampleRate: 1.0,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.httpClientIntegration(),
    Sentry.replayIntegration(),
  ],
})
Vue.use(Vuex)
const store = new Vuex.Store(storeData)
export const bus = new Vue()

Vue.use(DatePickerPlugin, { DatePicker })

Vue.use(ZiggyVue)
Vue.use(vClickOutside)
Vue.use(VTooltip)
Vue.use(VuePaginate)
Vue.use(DatePicker)
Vue.use(PortalVue)

Vue.component('Link', Link) // eslint-disable-line
Vue.component('Head', Head) // eslint-disable-line
// Vue.mixin({ methods: { route: window.route } })

// dayJs to format dates in frontend
dayjs.extend(duration)
dayjs.extend(relativeTime)
dayjs.extend(localizedFormat)

Object.defineProperties(Vue.prototype, {
  // Add dayJs to Vue with $date helper eg $date(Date.now())
  $date: {
    get() {
      return dayjs
    },
  },
})

createInertiaApp({
  resolve: (name) =>
    resolvePageComponent(
      `./pages/${name}.vue`,
      import.meta.glob('./pages/**/*.vue'),
    ),
  setup({ plugin, props, App, el }) {
    Vue.use(plugin)
    new Vue({ render: (h) => h(App, props), store }).$mount(el)
  },
  progress: {
    color: '#fc1862',
  },
})

var APP_ID = import.meta.env.VITE_INTERCOM_APP
;(function () {
  var w = window
  var ic = w.Intercom
  if (typeof ic === 'function') {
    ic('reattach_activator')
    ic('update', w.intercomSettings)
  } else {
    var d = document
    var i = function () {
      i.c(arguments)
    }
    i.q = []
    i.c = function (args) {
      i.q.push(args)
    }
    w.Intercom = i
    var l = function () {
      var s = d.createElement('script')
      s.type = 'text/javascript'
      s.async = true
      s.src = 'https://widget.intercom.io/widget/' + APP_ID
      var x = d.getElementsByTagName('script')[0]
      x.parentNode.insertBefore(s, x)
    }
    if (document.readyState === 'complete') {
      l()
    } else if (w.attachEvent) {
      w.attachEvent('onload', l)
    } else {
      w.addEventListener('load', l, false)
    }
  }
})()

if (window.Intercom) {
  window.Intercom('boot', {
    app_id: APP_ID,
  })
}
